<template>
  <div class="i-product-container" @click.stop="prepareBrowseProduct">
    <product-added-dialog-ok
      :showProductAddedConfirmation="showProductAddedConfirmation"
    />
    <div class="i-product">
      <div class="image-quadrat-cont i-product__picture">
        <span class="i-product__discount" v-show="product.compareAtPrice > 0">
          {{ discount }}
        </span>
        <div class="i-product__overlay">
          <img class="image-quadrat" :src="$getImgUrl(product.photo)" />
        </div>
      </div>
      <div class="i-product__content">
        <div class="i-product__brand">
          {{ product.brand }}
        </div>
        <div class="i-product__name">
          <template v-if="product.name.length <= '45'">
            {{ product.name }}
          </template>
          <template v-else> {{ product.name.slice(0, 45) + ".." }} </template>
        </div>

        <div class="i-product__footer">
          <div class="i-product__label-price">
            <!-- {{ product.price.toFixed(2) }} € -->

            <div class="i-product-price i-product-price--constructor">
              <div class="i-product-price__pricing">
                <ins>{{ $utils.formatMoney(product.price) }}</ins>
                <del v-show="product.compareAtPrice > 0">{{ compareAtPrice }}</del>
              </div>
            </div>
          </div>
          <div class="t-retailer">
            <div
              class="t-retailer__item"
              v-for="(retailer, index) in productRetailers"
              :key="index"
            >
              <img
                :src="$getLogoRetalier(retailer.name, retailer)"
                :alt="retailer.name"
                class="t-retailer__logo"
                width="30"
                height="30"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="i-product__actions">
        <div class="i-product-actions">
          <template v-if="!added">
            <loading-button v-if="loading" :class="'button-loading--sm'" />
            <div
              v-else
              class="button button--sm button--block button--transparent"
              @click.stop="addProductToList()"
            >
              <img src="../../../assets/img/ico/ico-add.svg" alt="" />
              Añadir
            </div>
          </template>
          <div
            v-else
            class="button button--sm button--block button--transparent button--delete"
          >
            <img src="../../../assets/img/ico/ico-check-grey.svg" alt="" />
            Añadido
          </div>
        </div>
      </div>
    </div>
    <modal-dialog
      :show="showProductAddedDialog"
      @close="showProductAddedDialog = false"
      target-class="modal__card--mini"
    >
      <product-added-to-list />
    </modal-dialog>
  </div>
</template>

<script>
import LoadingButton from "../../../components/LoadingButton";
import ModalDialog from "../../../components/ui/ModalDialog";
import Product from "./Mixins/Product";
import ProductAddedToList from "../../../components/ProductAddedToList";
import ProductAddedDialogOk from "../../../components/ProductAddedDialogOk";

export default {
  name: "ProductCard",
  mixins: [Product],
  components: {
    LoadingButton,
    ModalDialog,
    ProductAddedToList,
    ProductAddedDialogOk,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    section: {
      type: Object,
      required: false,
    },
    isConstructor: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toggleMoreShops: false,
      showProductAddedConfirmation: false,
    };
  },
  computed: {
    productRetailers() {
      if (this.product.isFree) {
        return [
          {
            name: this.$utils.capitalize(this.product.retailerName),
            price: this.product.price,
          },
        ];
      }
      return (
        this.product.productRetailers?.slice(
          0,
          this.toggleMoreShops ? this.product.productRetailers?.length : 10
        ) || []
      );
    },
    productUrl() {
      var res = this.$router.resolve(this.to).href;
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === "production") return "https://www.hellobb.net" + res;
      return res;
    },
    to() {
      var _name = "product";
      var _url = this.brandUrl;
      if (this.product.metaInfo?.title) {
        _name = "product-new";
        _url = this.nameUrl;
      }
      let to = {
        name: _name,
        params: { name: _url, brand: _url, id: this.product.id },
      };
      if ("filters" in this.$route.query || "sort" in this.$route.query) {
        to.query = {
          back: true,
        };
      }
      return to;
    },
    brandUrl: function () {
      if (this.product.url) {
        return this.$formatUrlhelloBB(this.product.url);
      } else {
        return this.$formatUrlhelloBB(this.product.brand);
      }
    },
    nameUrl: function () {
      return this.$formatUrlhelloBB(this.product.metaInfo?.title);
    },
    compareAtPrice() {
      return this.$utils.formatMoney(this.product.compareAtPrice);
    },
    discount() {
      return (
        "-" +
        Math.round(
          ((this.product.compareAtPrice - this.product.minPrice) /
            this.product.compareAtPrice) *
            100
        ) +
        "%"
      );
    },
  },
  methods: {
    mixpanelClickOnElementConstructor() {
      window.mixpanel.track("Click element constructor", {
        Name: this.product.name,
        Section: this.section.title,
        "Section number": this.section.id,
        Type: "ProductCard",
        Content: "Ecommerce",
      });
    },
    mixpanelClickOnElementCercaConstructor() {
      window.mixpanel.track("Click element cerca constructor", {
        Name: this.product.name,
        Type: "ProductCard",
      });
    },
    prepareBrowseProduct() {
      if (!this.isConstructor) {
        this.mixpanelClickOnElementCercaConstructor();
        this.$router.push(this.to);
      } else {
        this.mixpanelClickOnElementConstructor();
        this.$emit("click");
        this.browseProduct();
      }
    },
    addProductToList() {
      this.addProduct(
        this.isConstructor ? "Constructor - Grid" : "Constructor - Cercador"
      );
    },
  },
};
</script>
